<template>
     <div class="custom-file-container" data-upload-id="myUniqueUploadId">
                <label
                    >上传图片
                    <a
                        href="javascript:void(0)"
                        class="custom-file-container__image-clear"
                        title="Clear Image"
                        >&times;</a
                    ></label
                >
                <label class="custom-file-container__custom-file">
                    <input
                        type="file"
                        class="custom-file-container__custom-file__custom-file-input"
                        accept="image/*"
                        multiple
                        aria-label="Choose File"
                        @click="openFile"

                    />
                    <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                    <span
                        class="custom-file-container__custom-file__custom-file-control"
                    ></span>
                </label>
                <div class="custom-file-container__image-preview"></div>
            </div>
</template>

<script>
import { onMounted } from 'vue'
import FileUploadWithPreview from "file-upload-with-preview"

export default {
    name:'upimgs',
    props:[
        'myimages'
    ],

    setup (props, { emit }) {
        let upload = null
        


        // upload.addFiles(props.myimages)

        function fileChanged (e) {
            emit('changed', e.detail.cachedFileArray)
        }

        onMounted(() => {
            upload = new FileUploadWithPreview("myUniqueUploadId", {
                maxFileCount: 0, // 最大文件数量
                text: {
                    chooseFile: "请选择图片文件",
                    browse: "浏 览",
                    selectedCount: "个文件已选择"
                }
            })

            window.addEventListener("fileUploadWithPreview:imagesAdded", fileChanged);
            window.addEventListener("fileUploadWithPreview:imageDeleted", fileChanged);
            

            if (props.myimages) {
                upload.addFiles(props.myimages)
            }
        })

        
        /* 打开上传图片 */
        function openFile () {
            upload.emulateInputSelection()
        }
        
        return {
            openFile
            

        }
    }

}
</script>

<style>

</style>